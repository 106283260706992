const highPriority = ({ value = false } = {}) => {
  return {
    label: 'High',
    kind: 'high',
    key: 100,
    value,
  }
}
const mediumPriority = ({ value = false } = {}) => {
  return {
    label: 'Medium',
    kind: 'medium',
    key: 50,
    value,
  }
}
const lowPriority = ({ value = false } = {}) => {
  return {
    label: 'Low',
    kind: 'low',
    key: 0,
    value,
  }
}

const getManualFiltersFields = () => {
  return {
    priorityList: [highPriority(), mediumPriority(), lowPriority()],
    txAmountRange: {
      from: '',
      to: '',
    },
    triggeredAmountRange: {
      from: '',
      to: '',
    },
    scoreRange: {
      from: '',
      to: '',
    },
    listDates: [],
    depthRange: {
      from: '',
      to: '',
    },
    labels: [],
  }
}

export { getManualFiltersFields, highPriority, mediumPriority, lowPriority }
