export function getValueByKey(array, key, resultKey) {
    if (!array || array && !array.length || !key) return
    const obj = array.find(e => e.name === key)
    return obj && obj[resultKey] || ''
}

export function getObjectByKey({array, arrayKey, key}) {
  if (!array || (array && !array.length) || !key) return
  const obj = array.find(e => e[arrayKey] === key)
  return obj || ''
}

export async function toClipBoard(value, msg = 'Copied!') {
    await navigator.clipboard.writeText(value).then(() => {
        this.$toasted.global.success({ message: msg })
    })
}

export function deleteByPath(obj, path) {
    if (typeof path === 'string') {
        path = path.split('.');
    }

    if (path.length === 1 && !path[0].includes('[]')) {
        delete obj[path[0]];
        return obj
    }

    if (path[0].includes('[]')) {
        const arrayName = path[0].split('[]')[0];
        const targetName = path[0].split('[]')[1];

        if (arrayName && obj[arrayName] && Array.isArray(obj[arrayName]) && obj[arrayName].length) {
            obj[arrayName] = obj[arrayName].map(el => deleteByPath(el, targetName))
        }

    // eslint-disable-next-line no-prototype-builtins
    } else if (obj.hasOwnProperty(path[0]) && typeof obj[path[0]] === 'object') {
        deleteByPath(obj[path[0]], path.slice(1));
        if (Object.keys(obj[path[0]]).length === 0) {
            delete obj[path[0]];
        }
    }

    return obj
}

export function getNestedValueFromObj(obj, path) {
    if (!path || !Object.keys(obj).length) return
    return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

export function addByPath(object, path, value) {
    const keys = path.split('.');
    let current = object;

    for (let i = 0; i < keys.length - 1; i++) {
      if (!current[keys[i]]) {
        current[keys[i]] = (keys[i + 1] === '[]' ? [] : {});
      }
      current = current[keys[i]];
    }

    if (keys[keys.length - 1] === '[]') {
      current.push(value);
    } else {
      current[keys[keys.length - 1]] = value;
    }

    return object;
}

export function splitArrayByFixedSize(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
}

export function getTimestampFromObjectId(objectId) {
    return new Date(parseInt(objectId.slice(0, 8), 16) * 1000);
}

export function forceDeleteTooltipPopover () {
    const popoverElement = document.querySelector('.vue-popover');
    if (popoverElement) {
        popoverElement.remove();
    }
}
export function dispatchEventToRef({ refEl, eventName }) {
    if (!refEl) return
    const event = new Event(eventName);
    refEl.dispatchEvent(event);  
}
export function isObject(value) {
    return typeof value === "object" && value !== null && !Array.isArray(value);
}

export function compareObjectFields({ objA, objB, compareKeys = [], strict = false }) {
    if (
      typeof objA !== 'object' ||
      typeof objB !== 'object' ||
      objA === null ||
      objB === null
    ) {
      if (strict) {
        return objA === objB
      } else {
        return objA == objB
      }
    }
  
    const keysA = Object.keys(objA).filter(
      key => !compareKeys.length || compareKeys.includes(key),
    )
    const keysB = Object.keys(objB).filter(
      key => !compareKeys.length || compareKeys.includes(key),
    )
  
    if (keysA.length !== keysB.length) return false
  
    return keysA.every(key => {
      if (Array.isArray(objA[key]) && Array.isArray(objB[key])) {
        if (objA[key].length !== objB[key].length) return false
        return objA[key].every((itemA, index) => {
          return compareObjectFields({
            objA: itemA,
            objB: objB[key][index],
            compareKeys,
            strict
          })
        })
      }
      return compareObjectFields({
        objA: objA[key],
        objB: objB[key],
        compareKeys,
        strict
      })
    })
  }