/* eslint-disable no-unused-vars */
import apiService from '@/utils/api-client'
import analytics from "@/store/modules/analytics";
import config from "@/utils/appConfig";
import { featureAccess } from "@/utils/accesses";
import { toQueryParams } from "@/utils/url-formatter";

export default {
    // eslint-disable-next-line no-unused-vars
    createAlert({ commit }, data) {
        const sendedData = data
        try {
            const data = apiService.post('/alert', { ...sendedData }, { baseURL: config.VUE_APP_API_USERS_URL })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAlertsList({ commit }, { $skip = 0, $limit = 10, monitoring = undefined }) {
        try {
            return apiService.get('/alert', { params: { $skip, $limit, monitoring }, baseURL: config.VUE_APP_API_USERS_URL })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAlertsById({ commit }, id) {
        try {
            return apiService.get(`/alert/${id}`, { baseURL: config.VUE_APP_API_USERS_URL })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAlertsByIds({ commit }, id) {
        try {
            return apiService.get(`/alert`, { params: { id }, baseURL: config.VUE_APP_API_USERS_URL })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAlertsByAddress({ commit }, address) {
        try {
            return apiService.get(`/alert/-/address/${address}`)
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAlertDataByAddress({ commit }, { address, alertId }) {
        try {
            return apiService.get(`/alert/${alertId}/address/${address}`)
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAddressesForAlert({ commit }, id) {
        try {
            return apiService.get(`/alert/${id}/address`, { baseURL: config.VUE_APP_API_USERS_URL })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    updateAlert({ commit }, { id, data }) {
        const { archive, disabled, retrospective, after, title, description, priority, email } = data

        return apiService.patch(`/alert/${id}`, { archive, disabled, retrospective, after, title, description, priority, email }, { baseURL: config.VUE_APP_API_USERS_URL })
    },
    // eslint-disable-next-line no-unused-vars
    addAlertToAddresses({ commit }, { id, addresses }) {
        return apiService.put(`/alert/${id}/address`,  { address: addresses, coin: analytics.state.coinData.key }, { baseURL: config.VUE_APP_API_USERS_URL })
    },
    // eslint-disable-next-line no-unused-vars
    bindAlertToAddresses({ commit }, { id, addresses, coin }) {
        const url = analytics?.state?.currencyList.find(el => el.key === coin).baseUrl

        return apiService.put(`/alert/${id}/address`,  { address: addresses, coin }, { baseURL: url })
    },
    // eslint-disable-next-line no-unused-vars
    deleteAlert({ commit }, id) {
        return apiService.delete(`/alert/${id}`, { baseURL: config.VUE_APP_API_USERS_URL });
    },
    // eslint-disable-next-line no-unused-vars
    deleteAlertToAddressPair({ commit }, { id, address }) {
        return apiService.delete(`/alert/${id}/address`, { data: { address }, baseURL: config.VUE_APP_API_USERS_URL });
    },

    // eslint-disable-next-line no-unused-vars
    deleteAlertByCoin({ commit }, { id, addresses, url }) {
        return apiService.delete(`/alert/${id}/address`, { data: { addresses, alertId: id }, baseURL: url });
    },

    // Notifications

    // eslint-disable-next-line no-unused-vars
    async getAlertStat({ commit }, { alertId }) {
        try {
            return apiService.get(`/alert-notification/alert/-/stats`,{ params: { alertId }, baseURL: config.VUE_APP_API_USERS_URL  })
        } catch (error) {
            console.error(error)
        }
    },
    async getNotificationList(
        { commit },
        {
          cancelToken,
          $skip = 0,
          $limit = 10,
          read,
          $project,
          category = 'inbox',
          $sort = undefined,
          ai_sort = undefined,
          amountRange = undefined,
          triggeredAmountRange = undefined,
          scoreRange = undefined,
          depthRange = undefined,
          dateRange = undefined,
          priorityRange = undefined,
          coin = undefined,
        },
      ) {
        if (!featureAccess('ALERTS_AI_SORT')) {
          ai_sort = undefined
        }
        try {
          return apiService.get(`/alert-notification/${category}`, {
            cancelToken,
            params: {
              $skip,
              $limit,
              read,
              $project,
              ai_sort,
              $sort,
              amountRange,
              triggeredAmountRange,
              scoreRange,
              depthRange,
              dateRange,
              priorityRange,
              coin,
            },
            baseURL: config.VUE_APP_API_USERS_URL,
          })
        } catch (error) {
          console.error(error)
        }
    },
    updateAllAlertNotifications(
        { commit },
        {
          actionType,
          actionTypeValue,
          category,
          id = undefined,
          amountRange = undefined,
          triggeredAmountRange = undefined,
          scoreRange = undefined,
          depthRange = undefined,
          dateRange = undefined,
          priorityRange = undefined,
          coin = undefined,
          ai_sort = undefined,
        },
      ) {
        if (!featureAccess('ALERTS_AI_SORT')) {
            ai_sort = undefined
        }
        try {
            const params = Object.fromEntries(
                Object.entries({
                    [actionType]: !actionTypeValue,
                    amountRange,
                    triggeredAmountRange,
                    scoreRange,
                    depthRange,
                    dateRange,
                    priorityRange,
                    coin,
                    ai_sort,
                }).filter(([_, value]) => value !== undefined),
            )
          
            if (id) params.id = { [id.length === 1 ? '$ne' : '$nin']: id }
            const queryParams = toQueryParams(params)
          
            return apiService.patch(
              `/alert-notification/${category}?${queryParams}`,
              { [actionType]: actionTypeValue },
              { baseURL: config.VUE_APP_API_USERS_URL },
            )

        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    changeArchiveFlag({ commit }, { id, archive, read, category = 'inbox' }) {
        const queryParams = Array.isArray(id)
            ? id.map(id => `id[]=${id}`).join('&')
            : `id[]=${id}`

        return apiService.patch(
            `/alert-notification/${category}/?${queryParams}`,
            { archive, read },
            { baseURL: config.VUE_APP_API_USERS_URL },
        )
    },
    // eslint-disable-next-line no-unused-vars
    getSmartFilters({ commit }) {
        try {
            return apiService.get(`alert-notification/alert/smartfilters/list`,  { baseURL: config.VUE_APP_API_USERS_URL })
        } catch (error) {
            console.error(error)
        } 
    },
    getSmartFiltersCounter({ commit }, { coin = undefined }) {
        try {
            return apiService.get(`/alert-notification/alert/smartfilters`,  { params: { coin } ,baseURL: config.VUE_APP_API_USERS_URL })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    getSubscriptions({ commit }, { $project = 0 }) {
        return apiService.get(`/subscriptions`,  { params: { $project }, baseURL: config.VUE_APP_API_USERS_URL })
    },
   
}