import axios from 'axios';
import Vue from 'vue';
import store from '@/store';
import configApp from '@/utils/appConfig';
import router from "@/router";
import qs from 'qs'

const apiClient = axios.create();

let cancelTokenSource = axios.CancelToken.source(); // Variable to hold the cancel token source

apiClient.interceptors.request.use(config => {
  // Generate a unique request key based on the request URL and method
  const requestKey = `${config.url}_${config.method}_${JSON.stringify(config.params)}`;

  // List of routes for which requests should not be canceled
  const routesToExcludeCancellation = ['txBrowserIO', 'reset-token', 'entity'];
  const shouldExcludeCancellation = routesToExcludeCancellation.some(keyword =>
    config.url.includes(keyword)
  );

  // Cancel previous request (if any) with the same key
  if (cancelTokenSource && cancelTokenSource[requestKey] && router.currentRoute.name === 'analytics' && !shouldExcludeCancellation) {
    cancelTokenSource[requestKey].cancel('Request canceled');
  }

  if (router.currentRoute.name === 'analytics' && !shouldExcludeCancellation) {
    cancelTokenSource[requestKey] = axios.CancelToken.source(); // Create a new cancel token source for the request
    config.cancelToken = cancelTokenSource[requestKey].token; // Set the cancel token on the request config
  }
  config.paramsSerializer = p => {
    return qs.stringify(p, {arrayFormat: 'brackets'})
  }

  config.baseURL = config.baseURL || store.state.analytics.coinData.baseUrl || configApp.VUE_APP_API_BASE_URL;
  const token = localStorage.getItem('token');
  if (token) config.headers['Authorization'] = `Bearer ${token}`;
  return config;
}, error => error);

let refreshTokenPromise

apiClient.interceptors.response.use(
    res => {
      if (res?.config?.url === 'users/me' && !res?.data?.success) {
          Vue.toasted.global.error({ message: `${res?.data?.data?.message}` })
          localStorage.removeItem('token')
          window.location.assign('/')
      }
      if (res?.data?.data?.status === 422 && router.currentRoute.name !== 'login')  {
          localStorage.setItem('lastRoute', router.currentRoute.name)
      }
      if (res?.data?.data?.status === 501) {
        Vue.toasted.global.info({ message: `This feature has not been implemented yet for selected blockchain - ${store.state.analytics.coinData.label}` })
        // throw new Error('Not implemented')
      }

      if (res.data && !res.data.status && res.data.data === null) {
        Vue.toasted.global.error({ message: 'API REQUEST ERROR' })
        throw new Error('API REQUEST ERROR')
      }
      // Handle response data
      return res.data;
    },
    async error => {
      if (error?.response?.status === 401) {
          const refreshToken = localStorage.getItem('refreshToken')
          localStorage.removeItem('token')
          localStorage.setItem('lastRoute', router.currentRoute.name)

          if (refreshToken && refreshToken !== 'undefined') {
              if (!refreshTokenPromise) {
                  refreshTokenPromise = store.dispatch('user/resetToken', { refreshToken })
                      .then(() => {
                          refreshTokenPromise = undefined

                          const fullPath = localStorage.getItem('to.fullPath') || router.currentRoute.name

                          return router.push(fullPath)
                      })
                      .catch(() => {
                          window.location.assign('/login')
                      })
              }
          } else {
              window.location.assign('/login')
          }
      }
      // Handle response error
      throw error;
    }
);

export function cancelRequest(requestKey) {
  if (cancelTokenSource && cancelTokenSource[requestKey]) {
    cancelTokenSource[requestKey].cancel('Request canceled');
    delete cancelTokenSource[requestKey];
  }
}

export default apiClient;
